<template>
  <b-container
    fluid
    class="px-0 ml-1"
  >
    <b-row
      align-h="between"
    >

      <!-- #region begin::Product details title -->
      <b-col cols="6">
        <OrderProductTitleDetails
          :id="product.IdProduct"
          :name="product.Name"
          :type="product.Type"
          :count="product.QtyIventory"
          :save-route="false"
          :delivered="product.Delivered"
          :set-pieces-query="setPiecesQuery"
          :product="setPiecesQuery ? product : null"
        />
      </b-col>
      <!-- #endregion end::Product details title -->

      <b-col cols="6">
        <b-container fluid>
          <b-row
            align-h="end"
            align-v="center"
          >

            <b-col md="7">
              <div class="d-flex justify-content-center">
                <span class="align-self-center text-nowrap mr-1">Método de entrega</span>
                <div class="w-100">
                  <SelectWithValidation
                    v-model="selectedDeliveryMethod"
                    :vid="deliveryMethodUUID"
                    name="método de entrega"
                    rules="required"
                    property="text"
                    placeholder="Selecciona el método de entrega"
                    :options="getDeliveryMethods"
                    :disabled="disabledFormComponents || disableEditingOptions"
                    :clearable="false"
                  />
                </div>
              </div>
            </b-col>

            <!-- #region begin::Delete product button -->
            <b-button
              v-if="showDeleteButton"
              v-b-tooltip.hover.top="'Eliminar'"
              variant="flat-secondary"
              class="btn-icon px-2"
              :disabled="disableEditingOptions"
              @click="onDeleteProduct"
            >
              <feather-icon icon="Trash2Icon" />
            </b-button>
            <!-- #endregion end::Delete product button -->

            <!-- #region begin::Edit product button -->
            <b-button
              v-if="showEditButton"
              v-b-tooltip.hover.top="'Editar'"
              variant="flat-secondary"
              class="btn-icon px-2"
              @click="$refs.updateProductModal.showModal()"
            >
              <feather-icon icon="Edit3Icon" />
            </b-button>
            <!-- #endregion end::Edit product button -->

          </b-row>
        </b-container>
      </b-col>
    </b-row>

    <OrderProductDetails
      :product="product"
      :disabled-form-components="disabledFormComponents"
      :icon-toggle="iconToggle"
      :visible="visible"
      @toggle-collapse="collapse"
    />

  </b-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import {
  BRow, BCol, BContainer, BButton, VBTooltip,
} from 'bootstrap-vue'

import SelectWithValidation from '@/components/forms/SelectWithValidation.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import OrderProductDetails from '@/modules/trade/orders/components/cards/OrderProductDetails.vue'
import OrderProductTitleDetails from '@/modules/trade/orders/components/cards/OrderProductTitleDetails.vue'

import { v4 as uuidv4 } from 'uuid'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BContainer,
    OrderProductDetails,
    SelectWithValidation,
    OrderProductTitleDetails,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    product: {
      type: Object,
      required: true,
    },
    isVisible: {
      type: Boolean,
      default: false,
    },
    collapseItemId: {
      type: String,
      required: true,
    },
    showDeleteButton: {
      type: Boolean,
      default: true,
    },
    showEditButton: {
      type: Boolean,
      default: true,
    },

    // * 22/11/2022 - TAG: For quote details
    disabledFormComponents: {
      type: Boolean,
      default: false,
    },
    setPiecesQuery: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      visible: false,
      iconToggle: 'ChevronDownIcon',
      selectedDeliveryMethod: null,
      /**
       * Información del usuario loggeado
       */
      userData: JSON.parse(localStorage.getItem('userData')),
    }
  },
  computed: {
    ...mapGetters({
      getDeliveryMethods: 'quotes/getDeliveryMethods',
    }),
    deliveryMethodUUID() {
      return uuidv4()
    },
    disableEditingOptions() {
      return this.userData.Role === 'Coordinador de Almacen'
    },
  },
  watch: {
    selectedDeliveryMethod(newDeliveryMethod) {
      this.$set(this.product, 'DeliveredMethod', newDeliveryMethod.text)
    },
  },
  created() {
    this.visible = this.isVisible
    this.setToggleIcon()

    if (this.product.DeliveredMethod) {
      this.selectedDeliveryMethod = { text: this.product.DeliveredMethod }
    }
  },
  methods: {
    ...mapActions({
      addDetailProduct: 'orders/addDetailProduct',
    }),
    collapse() {
      if (!this.visible) {
        this.$emit('rendering', true)
      }
      this.$root.$emit('bv::toggle::collapse', this.collapseItemId)
      this.visible = !this.visible
      this.setToggleIcon()
    },
    setToggleIcon() {
      this.iconToggle = this.visible ? 'ChevronUpIcon' : 'ChevronDownIcon'
    },
    onDeleteProduct() {
      const itHasDeliveredPieces = piece => piece.Delivered === 'Entregado'
      const hasDeliveredPieces = this.product.pieces.some(itHasDeliveredPieces)

      if (hasDeliveredPieces) {
        this.showToast(
          'Error de validación',
          'El producto no puede ser eliminado ya que tiene piezas entregadas.',
          'warning',
        )

        return
      }

      const orderProduct = { ...this.product }
      orderProduct.pieces = []
      this.addDetailProduct(orderProduct)

      this.showToast(
        'Producto eliminado',
        'El producto se ha eliminado correctamente de la orden',
        'success',
      )
    },
    showToast(title, text, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title, text, variant, icon: 'BellIcon',
        },
      })
    },
  },
}
</script>
